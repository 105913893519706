import React from "react"

import Layout from '../../components/layout'
import Head from '../../components/head'
import TeamsTabbed from "../../components/teams_tabbed"

const Teams = () => {


    return (
        <Layout>
            <Head
                title="NFL EPA Tiers by Team"
                pageDescription="NFL Expected Point Added (EPA) Tiers by Team"
            />
            <TeamsTabbed
                activeIndex={3}
            />
        </Layout>
    )
}

export default Teams

